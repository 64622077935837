﻿$(document).ready(function () {

    function getUrlParameter(sParam) {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
        return false;
    };

    function removeURLParameter(url, parameter) {
        //prefer to use l.search if you have a location/link object
        var urlparts = url.split('?');
        if (urlparts.length >= 2) {

            var prefix = encodeURIComponent(parameter) + '=';
            var pars = urlparts[1].split(/[&;]/g);

            //reverse iteration as may be destructive
            for (var i = pars.length; i-- > 0;) {
                //idiom for string.startsWith
                if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                    pars.splice(i, 1);
                }
            }

            url = urlparts[0] + '?' + pars.join('&');
            return url;
        } else {
            return url;
        }
    }

    //Adding this as IE doesn't support endswith
    if (!String.prototype.endsWith) {
        String.prototype.endsWith = function (pattern) {
            var d = this.length - pattern.length;
            return d >= 0 && this.lastIndexOf(pattern) === d;
        };
    }
    
    $("#lnkForgotPassword").on("click", function (event) {
        event.preventDefault();
        var email = $("#Username").val();

        var forgotPasswdlink = "";
        var txtreturnURL = $("#hidd_txtReturnURL").val() || "";
        var currentLoc = window.location.href;
        
        if (currentLoc.toLowerCase().endsWith("/account/login/")) {
            currentLoc = currentLoc.slice(0, -1);
        }
       
        forgotPasswdlink = "/Account/ForgotPassword?email=" + encodeURIComponent(email);

        //TR the order of parameters shouldn't really matter but we've been running in to trouble if with
        //the browser thinking the ct and ctid parameters are part of the returnurl, for now fixing by
        //forcing the returnurl to the end of the querystring.
        if (currentLoc.indexOf("ReturnUrl" > -1) && getUrlParameter("ct")) {
            returnUrlVal = getUrlParameter("ReturnUrl");
            currentLoc = removeURLParameter(currentLoc, "ReturnUrl");
            currentLoc += "&ReturnUrl=" + returnUrlVal;
        }

        if (currentLoc.toLowerCase().indexOf("/account/login?returnurl=") > 0) {
            forgotPasswdlink += "&forgotPassRedirectURL=" + encodeURIComponent(currentLoc);
        }
        else if ((txtreturnURL.length > 0) && (currentLoc.toLowerCase().endsWith("/account/login"))) {
            forgotPasswdlink += "&forgotPassRedirectURL=" + encodeURIComponent(currentLoc) + "?ReturnUrl=" + txtreturnURL;
        }
        else {
            forgotPasswdlink += "&forgotPassRedirectURL=" + encodeURIComponent(currentLoc);
        }
        window.location.href = forgotPasswdlink;
    });

    $("#lnkRegister").on("click", function (event) {
        event.preventDefault();        

        var userRegisterLink = "";
        var txtreturnURL = $("#hidd_txtReturnURL").val() || "";
        var currentLoc = window.location.href;

        if (currentLoc.toLowerCase().endsWith("/account/login/")) {
            currentLoc = currentLoc.slice(0, -1);
        }

        if (currentLoc.toLowerCase().indexOf("/account/login?returnurl=") > 0) {
            userRegisterLink = "/Account/UserRegistration?redirectURL=" + currentLoc;
        }
        else if ((txtreturnURL.length > 0) && (currentLoc.toLowerCase().endsWith("/account/login"))) {
            userRegisterLink = "/Account/UserRegistration?redirectURL=" + currentLoc + "?ReturnUrl=" + txtreturnURL;
        }
        else {
            userRegisterLink = "/Account/UserRegistration?redirectURL=" + currentLoc;
        }
        window.location.href = userRegisterLink;
    });

    $("#btnResetViaEmail").on("click", function (event) {
        $("#hdnDoPWDResetViaEmail").val(true);
    });

    $("#btnResetViaSMS").on("click", function (event) {
        $("#hdnDoPWDResetViaEmail").val(false);
    });

    $("#btnContinue").on("click", function (event) {        
        event.preventDefault();        
        window.location.href = $("#redirectURL").val();
    });

    $("a[data-target='PasswordComplexity']").on("click", function (event) {
        event.preventDefault();
        $("#PasswordComplexity").slideToggle("fast");
    });
    
    
    $("#show_password").on("click", function () {
         //Change the attribute  to password  or text
        let pwdTypeVal = $('#txtPassword').attr('type');
        if (pwdTypeVal == 'password') {
            $('#txtPassword').attr('type', 'text');
            $('.pwdshow').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
        }
        else if (pwdTypeVal == 'text') {
            $('#txtPassword').attr('type', 'password');
            $('.pwdshow').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
        }        
    });

    $("#show_confirm_password").on("click", function () {
        //Change the attribute  to password  or text
        let pwdTypeVal = $('#txtConfirmPassword').attr('type');
        if (pwdTypeVal == 'password') {
            $('#txtConfirmPassword').attr('type', 'text');
            $('.confirmpwdshow').removeClass('fa fa-eye-slash').addClass('fa fa-eye');
        }
        else if (pwdTypeVal == 'text') {
            $('#txtConfirmPassword').attr('type', 'password');
            $('.confirmpwdshow').removeClass('fa fa-eye').addClass('fa fa-eye-slash');
        }
    });
});
