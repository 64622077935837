﻿$(document).ready(function () {


    $('a.btnRemoveMfa').click(function () {
        var $this = $(this);
        event.preventDefault();

        bootbox.confirm('Click OK to unregister this multi-factor authentication option.', function (result) {
            if (!result) return;

            var i = $this.attr('data-index');
            $.ajax({
                url: $this.attr('data-url') + "?provider=" + $this.attr('data-id'),
                type: "DELETE",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify({}),
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("RequestVerificationToken",
                        $('input:hidden[name="__RequestVerificationToken"]').val());
                },
                success: function () {
                    //$("#isRegistered" + i).addClass("d-none");
                    //$("#btnRemoveMfa" + i).addClass("d-none");
                    //$("#lnkRegister" + i).removeClass("d-none");
                    //$("#divMfaTile" + i).removeClass("bg-success text-white").addClass("bg-white");
                    if (localStorage) {
                        localStorage.removeItem("ls.user-mfa-check");
                    }
                    window.location = "/MfaRegistration";
                },
                error: function (jqXHR) {

                }
            });
        });
    });

    $('a.btnMakeDefault').click(function () {
        var $this = $(this);
        event.preventDefault();

        bootbox.confirm('Are you sure you want to make the selected MFA default?', function (result) {
            if (!result) return;

            var i = $this.attr('data-index');
            $.ajax({
                url: $this.attr('data-url') + "?provider=" + $this.attr('data-id'),
                type: "POST",
                contentType: "application/json",
                dataType: "json",
                data: JSON.stringify({}),
                beforeSend: function (xhr) {
                    xhr.setRequestHeader("RequestVerificationToken",
                        $('input:hidden[name="__RequestVerificationToken"]').val());
                },
                success: function () {
                    window.location = "/MfaRegistration";
                },
                error: function (jqXHR) {

                }
            });
        });
    });

    if ($("#modalMFARegWelcome").length) {
        $('#modalMFARegWelcome').modal('show');
    }

    if ($("#modalMFAOutage").length) {
        $('#modalMFAOutage').modal('show');
    }

})