import { createFidoRegistration, challengeUserByFido } from "./fido";
import { VerifyOTPCode } from "./OTP";
import { DrawQRCode, CompleteTotpRegistration } from "./TOTP";
import { verifyCode } from "./AuththenticationHandlers";
import { AcceptEULA } from "./eula";
//import { offline } from "./offline";
//import { Rate, RateOver, RateOut, RateSelected, SubmitComment } from "./feedback";

import './pages/profile';
import './pages/mfaRegistration';
import './pages/dashboard';
import './pages/register-otp';
import './pages/forgotpassword';
import './feedback';
import './announcement';
import './yauth-lite-dashboard';
//import '../utils/v3-checking';

import QRCode from 'qrcode';

(function () {

    let fidoRegistration = function () {
        let challenge = getElementValue("base64Challenge");

        if (challenge !== null) {
            let relyingPartyId = getElementValue("relyingPartyId");
            let base64UserHandle = getElementValue("base64UserHandle");
            let fidousername = getElementValue("fidoUsername");
            let completionUrl = getElementValue("completionUrl");
            let completedUrl = getElementValue("completedUrl");
            let errorUrl = getElementValue("errorUrl");

            console.log("Start Fido Registration");

            createFidoRegistration(
                challenge,
                relyingPartyId,
                base64UserHandle,
                fidousername,
                completionUrl,
                completedUrl,
                errorUrl
            );
        }
    }

    let challenge = function () {
        let mfaProvider = getElementValue("provider");

        if (mfaProvider !== null) {
            let challengeResponseUrl = getElementValue('challengeResponseUrl');

            if (mfaProvider === 'FidoMfaProvider') {
                let mfaCode = getElementValue("challengeCode")

                if (mfaCode !== null) {
                    let errorUrl = getElementValue('errorUrl');

                    challengeUserByFido(mfaCode, challengeResponseUrl, errorUrl)

                    let retryButton = document.getElementById('retryButton');
                    if (retryButton !== null) {
                        console.log('hook into retry button');
                        retryButton.addEventListener('click', function () {
                            challengeUserByFido(mfaCode, challengeResponseUrl, errorUrl)
                        })
                    }
                }
            }
            else {
                let verificationFunction = function () {
                    let verificationCode = getElementValue('verificationCode');
                    verifyCode(verificationCode, mfaProvider, challengeResponseUrl);
                };

                let verifySubmit = document.getElementById('verificationSubmit');

                if (verifySubmit !== null) {
                    verifySubmit.addEventListener('click', function () {
                        verifySubmit.disabled = true;
                        verificationFunction();
                    });
                }

                let verifyCodeEntry = document.getElementById('verificationCode');

                if (verifyCodeEntry !== null) {
                    verifyCodeEntry.addEventListener("keydown", function (e) {
                        if (window.event.key === "Enter") {
                            verificationFunction();
                        }
                    });
                }
            }
        }
    }

    let otpRegistration = function () {
        let otpVerifcationButton = document.getElementById("otpVerifcationButton");

        if (otpVerifcationButton !== null) {

            let verificationUrl = getElementValue("completeUrl");
            let completedUrl = getElementValue("completedUrl");
            let errorUrl = getElementValue("errorUrl");

            let verifyOtp = function(){
                let hotp = getElementValue("verification_code");

                VerifyOTPCode(hotp, verificationUrl, completedUrl, errorUrl);
            };

            let verificationcode = document.getElementById('verification_code');

            if (verificationcode !== null) {
                verificationcode.addEventListener("keydown", function (e) {
                    if (window.event.key === "Enter") {
                        verifyOtp();
                    }
                })
            }

            otpVerifcationButton.addEventListener("click", function () {
                verifyOtp();
            })
        }
    }

    let totpRegistration = function () {
        let totpVerificationSubmit = document.getElementById("totpVerificationSubmit");

        if (totpVerificationSubmit !== null) {

            DrawQRCode(document.getElementById("qrCode"), getElementValue("qrUrl"));

            let registrationExecute = function () { CompleteTotpRegistration(getElementValue('verificationCode'), getElementValue('completionUrl'), getElementValue('completedUrl')); };

            totpVerificationSubmit.addEventListener("click", function () {
                registrationExecute();
            })

            let totpVerification = document.getElementById("verificationCode");

            if (totpVerification !== null) {
                totpVerification.addEventListener("keydown", function (e) {
                    if (window.event.key === "Enter") {
                        registrationExecute();
                    }
                })
            }
        }
    }

    let generateMobSetupQRCode = function ()
    {
        if (document.getElementById("qrTenantData") !== null)
        {
            $("#qrUpdateMsg").hide();
            $("#divQRCurrentTenant").hide();

            if ($("#qrTenantData").val() !== "") {
                $("#divQRCurrentTenant").show();

                QRCode.toCanvas(document.getElementById("qrCodeCurrentTenant"), getElementValue("qrTenantData"));

                $("#Y1Subdomain").text(JSON.parse($("#qrTenantData").val()).TenantSubdomain);
            }
            else {
                $("#divQRCurrentTenant").hide();

                $("#qrUpdateMsg").text("Failed to generate QR Code");
                $("#qrUpdateMsg").removeClass('text-success');
                $("#qrUpdateMsg").addClass('text-danger');
                $("#qrUpdateMsg").show();
            }
        }
    }

    function getElementValue(elementId) {
        let element = document.getElementById(elementId);

        if (element === null) {
            return null;
        }

        return element.value.trim();
    }  

    

    function yardihelpinitialload()
    {
        if (document.getElementById('js-help-button') !== null)
        {
            let redirectYardiHelpScript = "<script>"
                + "function getQueryParams(name) {"
                + " let url = window.location.href;"
                + " name = name.replace(/[\\[\\]]/g, '\\$&');"
                + " let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),"
                + " results = regex.exec(url);"
                + " if (!results) {"
                + "    return null;"
                + " }"
                + " if (!results[2]) {"
                + "   return '';"
                + " }"
                + " return decodeURIComponent(results[2].replace(/\\+/g, ' '));"
                + "}"
                + "function redirectToYardiHelp() {"
                + "   let HelpArticle = getQueryParams('HelpArticle');"
                + "  let HelpSection = getQueryParams('HelpSection');"
                + "  if (HelpArticle && HelpArticle !== '') {"
                + "       $.fn.YH_Widget.JumpToResourceID(HelpArticle);"
                + "   }"
                + "   else if (HelpSection && HelpSection !== '') {"
                + "       $.fn.YH_Widget.JumpToSectionID(HelpSection);"
                + "  }"
                + "}"
                + "if ($ && $.fn['YH_Widget']) {"
                + "     redirectToYardiHelp();"
                + "}"
                + "else{"
                + " $.getScript('https://yh.yardielearning.com/YardiHelp').done(function () {"
                + "     setTimeout(function () {"
                + "         redirectToYardiHelp();"
                + "     }, 1400);"
                + " });"
                + "}"
                + "</script>";


            document.getElementById('js-help-button').style.pointerEvents = 'none';

            $.ajax({
                url: '/account/yardihelp',
                type: "GET",
                contentType: "application/json",
                success: function (helpdata) {
                    if (helpdata.YardiHelpId !== '') {
                        $.ajax({
                            url: '/account/yardihelp/profile',
                            type: "GET",
                            contentType: "application/json",
                            success: function (user) {
                                if (user.Email !== '') {
                                    $('body').append(''
                                        + '<script> var YardiHelpSettings = {tok: "' + helpdata.YardiHelpId + '", elTok: "' + helpdata.YardiHelpWebinarCalenderId + '", RelatedTok: "' + helpdata.YardiHelpRelatedTok + '", user_firstname: "' + user.FirstName + '", user_lastname: "' + user.LastName + '", user_email:"' + user.Email + '", AspireKeyword:"' + helpdata.AspireKeyword + '", clientPin:"' + helpdata.ClientPin + '" }; </script>'
                                        //+ '<script src="https://yh.yardielearning.com/YardiHelp"></script>'
                                        + redirectYardiHelpScript);
                                    
                                    document.getElementById('js-help-button').style.pointerEvents = 'auto';
                                }
                            },
                            error: function (jqXHR) {

                            }
                        });
                    }
                },
                error: function (jqXHR) {
                    console.log("p-error")
                }
            });
        }
    }

    function addYardiHelpEvent()
    {
        $('#js-help-button').bind('click', function (e) {
            e.preventDefault();

            document.getElementById('js-help-button').style.pointerEvents = 'none';

            $.getScript('https://yh.yardielearning.com/YardiHelp').done(function () {
                setTimeout(function () {
                    $.fn.YH_Widget.YardiModalHelp();
                    document.getElementById('js-help-button').style.pointerEvents = 'auto';
                }, 1400);
            });
        });
     }

    function resendVerficationCode()
    {
        $('#btnResend').bind('click', function (e) {
            e.preventDefault();
            if (ipromise) {
                return false;
            }
            var $this = $(this);

            $("#ResendCodeMsg").hide();

                $.ajax({
                    url: $this.attr('data-url'),
                    type: "GET",
                    contentType: "application/json",
                    success: function () {
                        startOTPValidityTimer();
                        $("#ResendCodeMsg").show();
                    },
                    error: function (jqXHR) {
                        let resText = JSON.parse(jqXHR.responseText);
                        if (resText && resText.ErrorMessage === "ThirtySecondLimit") {
                            $("#TimierLimit").show();
                        }
                        if (resText && resText.ErrorMessage === "MaxResendLimitReached") {
                            $("#opterrorMaxResendAttempt").show();
                            setTimeout(function () {
                                window.location.href = '/account/login?returnurl=' + resText.ReturnURL;
                            }, 2000);
                        }
                    }
                });
        });

        $('#btnCallResend').bind('click', function (e) {
            e.preventDefault();
            if (ipromise) {
                return false;
            }
            var $this = $(this);

            $("#ResendCodeMsg").hide();

            $.ajax({
                url: $this.attr('data-url'),
                type: "GET",
                contentType: "application/json",
                success: function () {
                    startOTPValidityTimer();
                    $("#ResendCodeMsg").show();
                },
                error: function (jqXHR) {
                    let resText = JSON.parse(jqXHR.responseText);
                    if (resText && resText.ErrorMessage === "ThirtySecondLimit") {
                        $("#TimierLimit").show();
                    }
                    if (resText && resText.ErrorMessage === "MaxResendLimitReached") {
                        $("#opterrorMaxResendAttempt").show();
                        setTimeout(function () {
                            window.location.href = '/account/login?returnurl=' + resText.ReturnURL;
                        }, 2000);
                    }
                }
            });
        });


    }

    let timer = 30;
    let ipromise = undefined;
    function startOTPValidityTimer() {
        if (document.getElementById("btnResend")) {
            $("#btnResend").css("pointer-events", "none");
            $("#btnResend").css("cursor", "default");
        }

        if (document.getElementById("btnCallResend")) {
            $("#btnCallResend").css("pointer-events", "none");
            $("#btnCallResend").css("cursor", "default");
        }
        timer = 30;
        if (ipromise) {
            clearInterval(ipromise);
            ipromise = undefined;            
        }

        ipromise = setInterval(function () {
            var seconds;
            seconds = parseInt(timer % 60, 10);
            let calSeconds = seconds < 10 ? "0" + seconds : seconds;
            $("#timerSeconds").text(calSeconds);
            $("#resendTimer").show();
            if (timer > 0) {
                timer--;
            } else {
                clearInterval(ipromise);
                ipromise = undefined;
                $("#resendTimer").hide();
                $("#TimierLimit").hide();                
                if (document.getElementById("btnResend")) {
                    $("#btnResend").css("pointer-events", "");
                    $("#btnResend").css("cursor", "");
                }

                if (document.getElementById("btnCallResend")) {
                    $("#btnCallResend").css("pointer-events", "");
                    $("#btnCallResend").css("cursor", "");
                }
            }
        }, 1000);
    }

    $(document).ready(function () {
        $("#loading").addClass('hide');
        $(document).bind('ajaxStart', function () {
            $("#loading").removeClass('hide');
        }).bind('ajaxStop', function () {
            $("#loading").addClass('hide');
        });
        if (document.getElementById("btnResend") || document.getElementById("btnCallResend")) {
            startOTPValidityTimer();
        }
    });

    function addLiteDashboardEvent() {                
        if (window.LiteDashboard) {
            LiteDashboard.init('#lite-dashboard-holder', '#lite-dashboard-icon');            
        }          
    }

    yardihelpinitialload()
    fidoRegistration();
    otpRegistration();
    totpRegistration();
    challenge();
    AcceptEULA();
    addYardiHelpEvent();
    //offline();
    resendVerficationCode();
    generateMobSetupQRCode();

    if (window.isAddLiteDashboard) {
        addLiteDashboardEvent();
    }

})()