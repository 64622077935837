﻿import $ from 'jquery';

$(document).ready(function () {
    $("#announcement").on("click", "a.delay", function (e) {
        $(this).parent().parent().remove();
    });

    $("#announcement").on("click", "a.close", function (e) {
        var $this = $(this);
        var id = $this.attr("data-id");
        var isCA = $this.attr("data-isCA");

        $.ajax({
            url: "/announcement/remove/" + id + "/IsClientAnnouncement/" + isCA,
            type: "POST",
            contentType: "application/json",
            success: function (response) {
                $this.parent().parent().remove();
            },
            error: function (jqXHR) {

            }
        });
    });

    $("#announcement").on("click", "a.more", function (e) {
        var $this = $(this);
        var id = $this.attr("data-id");
        var IsClientAnnouncement = $this.attr("data-isCA");
        var announcement = null;

        remainingMessages.forEach(function (x)
        {
            if ((x.Id === parseInt(id)) && (x.IsClientAnnouncement === IsClientAnnouncement))
            {
                announcement = x;
            }
        });

        if (announcement)
        {
            if (announcement.Message.indexOf('||') > -1)
            {
                var annmsg = announcement.Message.substring(0, announcement.Message.indexOf('||'));
                annmsg = annmsg + '<a href="javascript:void(0)" class="more" data-id="' + id + '" data-isCA="' + IsClientAnnouncement + '">more...</a>';

                var remainingMsg = announcement.Message.substring(announcement.Message.indexOf('||') + 2);
                remainingMessages.forEach(function (x) {
                    if ((x.Id === parseInt(id)) && (x.IsClientAnnouncement === IsClientAnnouncement))
                    {
                        x.Message = remainingMsg;
                    }
                });

                $(this).parent().append(annmsg);
                $(this)[0].parentNode.removeChild($(this)[0]);
            }
            else
            {
                var msg = announcement.Message;
                $(this).parent().append(msg);
                $(this)[0].parentNode.removeChild($(this)[0]);
            }
        }
    });
});

function getAnnouncements() {
    $.ajax({
        url: "/announcement",
        type: "GET",
        contentType: "application/json",
        success: function (data) {
            if (data && data.length) {
                bindAnnouncements(data);
            }
        },
        error: function (jqXHR) {

        }
    });
}

if ($("div#announcement").length)
    getAnnouncements();

////Check for announcements every 5 Minutes
//setInterval(function () {
//    getAnnouncements();
//}, 300000);

function bindAnnouncements(data) {
    if (!$("div#announcement").length) return;

    var html = '<div class="col-md-8 offset-md-2 mt-3"></div>';
    for (var i = 0; i <= data.length - 1; i++) {
        var mt = i === 0 ? "mt-3" : "";
        html += '<div class="col-md-8 offset-md-2">';
        html += '<div class="alert alert-' + data[i].AnnouncementType.toLowerCase() + ' text-left">';
        html += '<a href="javascript:void(0)" class="close" data-id="' + data[i].Id + '" data-isCA="' + data[i].IsClientAnnouncement + '">&times;</a><a href="javascript:void(0)" title="Delay" class="delay mr-1"><i class="far fa-clock"></i></a>';
        html += '<span class="notifyIcon"><i class="far fa-bell"></i></span>';
        html += '<h4 style="display: inline-block;">' + data[i].Name + '</h4><br>';
        html += getMessage(data[i]);
        html += '</div>';
        html += '</div>';
    }

    $("div#announcement").html(html);
}

var remainingMessages = [];

function getMessage(data) {
    //look for separator '||' to break message and show more... link
    if (data.Message.indexOf('||') > -1)
    {
        var msg = data.Message.substring(0, data.Message.indexOf('||'));
        msg = msg + '<a href="javascript:void(0)" class="more" data-id="' + data.Id + '" data-isCA="' + data.IsClientAnnouncement + '">more...</a>';

        var remainingMsg = data.Message.substring(data.Message.indexOf('||') + 2);
        remainingMessages.push({ Id: data.Id, Message: remainingMsg, IsClientAnnouncement: data.IsClientAnnouncement.toString() });
        return msg;
    }
    return data.Message;
}