﻿import QRCode from 'qrcode';
import * as intlTelInput from 'intl-tel-input/build/js/intlTelInput';
import 'intl-tel-input/build/js/utils';

$(document).ready(function () {

    if ($("#hdnLastLoginDate").val())
    {
        var DILastLoginDate = (new Date($("#hdnLastLoginDate").val())).toLocaleString();
        DILastLoginDate = DILastLoginDate.replace(",", "");

        var localtimezone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
        if (localtimezone)
        {
            $("#ddLastLoginDate").text(DILastLoginDate + " (" + localtimezone + ")");
        }
        else
        {
            $("#ddLastLoginDate").text(DILastLoginDate);
        }
    }

    if ($("#hdnLastPasswordChange").val()) {
        var DILastPasswordChange = (new Date($("#hdnLastPasswordChange").val())).toLocaleString();
        DILastPasswordChange = DILastPasswordChange.replace(",", "");

        var localtimezone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
        if (localtimezone) {
            $("#ddLastPwdChange").text(DILastPasswordChange + " (" + localtimezone + ")");
        }
        else {
            $("#ddLastPwdChange").text(DILastPasswordChange);
        }
    }

    function myProfileValidation() {
        var oldPasswd = $("#txtMyProfileOldPasswd").val();
        var newPasswd = $("#txtMyProfileNewPasswd").val();
        var confrmPasswd = $("#txtMyProfileConfrmPasswd").val();

        if ((oldPasswd.length > 0) && (newPasswd.length > 0) && (confrmPasswd.length > 0)) {
            if (document.getElementById("verifiedPassword").style.display === "none") {
                if (newPasswd === confrmPasswd) {
                    document.getElementById("btnMyProfileUpdatePasswd").disabled = false;
                }
                else {
                    document.getElementById("btnMyProfileUpdatePasswd").disabled = true;
                }
            }
            else {
                document.getElementById("btnMyProfileUpdatePasswd").disabled = true;
            }
        }
        else {
            document.getElementById("btnMyProfileUpdatePasswd").disabled = true;
        }
    }

    $("#txtMyProfileOldPasswd").keyup(function () {
        myProfileValidation();
    });

    $("#txtMyProfileNewPasswd").keyup(function () {
        var newPasswd = $("#txtMyProfileNewPasswd").val();

        var li_passwdReqlen = document.getElementById("li_passwdReqlen");
        var li_passwdReqdigit = document.getElementById("li_passwdReqdigit");
        var li_passwdReqSpecialChar = document.getElementById("li_passwdReqSpecialChar");
        var li_passwdReqLowCase = document.getElementById("li_passwdReqLowCase");
        var li_passwdReqUppCase = document.getElementById("li_passwdReqUppCase");
        var vryPasswd = document.getElementById("verifiedPassword");

            var verifiedPasswordLength = true;
            var verifiedPasswordHasNumber = true;
            var verifiedPasswordHasSpecialCharacter = true;
            var verifiedPasswordHasLowerCase = true;
            var verifiedPasswordHasUpperCase = true;

            if (li_passwdReqlen != null) {
                var reqLen = $("#li_passwdReqlen")[0].innerHTML.split("least ")[1].split(" ")[0];
                verifiedPasswordLength = (newPasswd.length >= reqLen);

                if (verifiedPasswordLength) {
                    li_passwdReqlen.style.color = "green";
                } else {
                    li_passwdReqlen.style.color = "red";
                }
            }

            if (li_passwdReqdigit != null) {
                verifiedPasswordHasNumber = /[0-9]+/.test(newPasswd);
                if (verifiedPasswordHasNumber) {
                    li_passwdReqdigit.style.color = "green";
                } else {
                    li_passwdReqdigit.style.color = "red";
                }
            }

            if (li_passwdReqSpecialChar != null) {
                verifiedPasswordHasSpecialCharacter = /[!@#$%^&*]+/.test(newPasswd);
                if (verifiedPasswordHasSpecialCharacter) {
                    li_passwdReqSpecialChar.style.color = "green";
                } else {
                    li_passwdReqSpecialChar.style.color = "red";
                }
            }

            if (li_passwdReqLowCase != null) {
                verifiedPasswordHasLowerCase = /[a-z]+/.test(newPasswd);
                if (verifiedPasswordHasLowerCase) {
                    li_passwdReqLowCase.style.color = "green";
                } else {
                    li_passwdReqLowCase.style.color = "red";
                }
            }

            if (li_passwdReqUppCase != null) {
                verifiedPasswordHasUpperCase = /[A-Z]+/.test(newPasswd);
                if (verifiedPasswordHasUpperCase) {
                    li_passwdReqUppCase.style.color = "green";
                } else {
                    li_passwdReqUppCase.style.color = "red";
                }
            }

            if (verifiedPasswordLength && verifiedPasswordHasNumber && verifiedPasswordHasSpecialCharacter && verifiedPasswordHasLowerCase && verifiedPasswordHasUpperCase) {
                vryPasswd.style.display = "none";
                myProfileValidation();
            }
            else {
                vryPasswd.style.display = "block";
                myProfileValidation();
            }
    });

    $("#txtMyProfileConfrmPasswd").keyup(function () {
        var newPasswd = $("#txtMyProfileNewPasswd").val();
        var confrmPasswd = $("#txtMyProfileConfrmPasswd").val();
        var divConfrmPasswd = document.getElementById("divMyProfileConfrmPass");
        
        if (confrmPasswd.length > 0)
        {
            if (newPasswd !== confrmPasswd) {
                divConfrmPasswd.style.display = "block";
                myProfileValidation();
            } else {
                divConfrmPasswd.style.display = "none";
                myProfileValidation();
            }
        }

        if (confrmPasswd.length == 0) {
            divConfrmPasswd.style.display = "none";
            myProfileValidation();
        }
    });

    var thubmnail = $("#hidThubmnail").val();
    if (thubmnail !== '') {
        var $imgProfile = $("#imgProfile");
        $imgProfile.attr("src", thubmnail);
        $imgProfile.show();

        $("#btnRemoveProfileImg").show();
    }
    else {
        $("#spDefaultProfileImg").show();
        $("#btnRemoveProfileImg").hide();
    }

    $("#btnUploadProfileImg").on('click', function () {
        var data = new FormData();
        var files = $("#profileImageUploader").get(0).files;
        if (files.length > 0) {
            data.append("file", files[0]);
        }
        else {
            return;
        }

        var $this = $(this);
        $this.find("i").show();

        $.ajax({
            url: '/account/manage/profile/thumbnail',
            type: "POST",
            processData: false,
            contentType: false,
            data: data,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (response) {
                if (response !== '') {
                    $("#spDefaultProfileImg").hide();
                    var $imgProfile = $("#imgProfile");
                    $imgProfile.attr("src", response);
                    $imgProfile.show();
                    $("#btnRemoveProfileImg").show();
                    $("#profileImageUploader").val(null);
                    $("#hidThubmnail").val('');
                    $this.find("i").hide();
                }
            },
            error: function (er) {
            }

        });
    });

    $("#btnRemoveProfileImg").on('click', function () {
        var $this = $(this);
        $this.find("i").show();
        $.ajax({
            url: '/account/manage/profile/thumbnail/remove',
            type: "POST",
            processData: false,
            contentType: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (response) {
                $("#spDefaultProfileImg").hide();
                var $imgProfile = $("#imgProfile");
                $imgProfile.attr("src", "");
                $imgProfile.hide();
                $("#btnRemoveProfileImg").hide();
                $("#spDefaultProfileImg").show();
                $("#profileImageUploader").val(null);
                $this.find("i").hide();
            },
            error: function (er) {
            }

        });
    });

    $("#btnResendSMS").on('click', function (event) {
        event.preventDefault();

        $.ajax({
            url: '/account/manage/resendsms',
            type: "POST",
            contentType: "application/json",
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (response) {
                $("#message").text("SMS confirmation has been sent to " + $("#hdnpwdresetphnumber").val() + ".");
                $("#message").addClass('text-success');
                $("#message").removeClass('text-danger');
                $("#message").show();

                var msgConfirmStatus = document.getElementById("lblPWDResetPhConfirmMsg");
                if (msgConfirmStatus)
                {
                    msgConfirmStatus.innerText = "This phone number is not confirmed by the user.";
                    msgConfirmStatus.style["color"] = "red";
                }

                $("#btnResendSMS").hide();
            },
            error: function (res) {
                if (res.responseText) {
                    $("#message").text(res.responseText);
                    $("#message").removeClass('text-success');
                    $("#message").addClass('text-danger');
                    $("#message").show();
                }
                else {
                    $("#message").text("Failed to send SMS.");
                    $("#message").removeClass('text-success');
                    $("#message").addClass('text-danger');
                    $("#message").show();
                }
            }
        });
    });

    $("#btnProfile").on('click', function (event) {
        event.preventDefault();
        var $this = $(this);
        var data;
        $("#message").hide();

        if (inputPH) {
            data = {
                FirstName: $("#FirstName").val(),
                LastName: $("#LastName").val(),
                PWDResetPhoneNumber: iti.getNumber(),
                ShowHideResendSMSBtn: $("#hdnshowresendsmsbtn").val(),
                PWDResetPhoneNumberConfirmStatus: $("#hdnpwdresetphconfirmstat").val()
            };
        }
        else {
            data = {
                FirstName: $("#FirstName").val(),
                LastName: $("#LastName").val(),
                PWDResetPhoneNumber: $("#hdnpwdresetphnumber").val(),
                ShowHideResendSMSBtn: $("#hdnshowresendsmsbtn").val(),
                PWDResetPhoneNumberConfirmStatus: $("#hdnpwdresetphconfirmstat").val()
            };
        }

        $.ajax({
            url: $this.attr('action'),
            type: "POST",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (res) {
                $("#message").text("Profile updated successfully");
                $("#message").addClass('text-success');
                $("#message").removeClass('text-danger');
                $("#message").show();

                const inputPH = document.querySelector('#pwdresetphnumber');
                if (inputPH) {
                    if (res.ShowHideResendSMSBtn == "true") {
                        $("#btnResendSMS").show();
                    }
                    else if (res.ShowHideResendSMSBtn == "false") {
                        $("#btnResendSMS").hide();
                    }

                    var msgConfirmStatus = document.getElementById("lblPWDResetPhConfirmMsg");

                    if (res.PWDResetPhoneNumberConfirmStatus == "0") {
                        msgConfirmStatus.innerText = "This phone number is not confirmed by the user.";
                        msgConfirmStatus.style["color"] = "red";
                    }
                }
            },
            error: function (res) {
                if (res.responseText) {
                    $("#message").text(res.responseText);
                }
                else {
                    $("#message").text("Profile updated failed");
                }

                $("#message").removeClass('text-success');
                $("#message").addClass('text-danger');
                $("#message").show();

                var msgConfirmStatus = document.getElementById("lblPWDResetPhConfirmMsg");
                if (msgConfirmStatus) {
                    msgConfirmStatus.innerText = "";
                }
            }
        });
    });

    const inputPH = document.querySelector('#pwdresetphnumber');
    if (!inputPH) { return; }

    const iti = intlTelInput(inputPH, {
        separateDialCode: true,
        utilsScript: "intl-tel-input/build/js/utils.js"
    });

    iti.setNumber($("#hdnpwdresetphnumber").val());

    var msgConfirmStatus = document.getElementById("lblPWDResetPhConfirmMsg");

    if ($("#hdnpwdresetphconfirmstat").val() == "0") {
        msgConfirmStatus.innerText = "This phone number is not confirmed by the user.";
        msgConfirmStatus.style["color"] = "red";
    }
    else if ($("#hdnpwdresetphconfirmstat").val() == "1") {
        msgConfirmStatus.innerText = "This phone number is confirmed by the user.";
        msgConfirmStatus.style["color"] = "green";
    }
    else if ($("#hdnpwdresetphconfirmstat").val() == "2") {
        msgConfirmStatus.innerText = "The phone number was received by the user, but they replied STOP.";
        msgConfirmStatus.style["color"] = "red";
    }
    else {
        msgConfirmStatus.innerText = "";
    }
})