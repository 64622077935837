﻿$(document).ready(function () {
    //var dashboardtimeoutid = 0;
    //$("#dashboard_search input.search-query").on("keydown", function () {
    //    clearTimeout(dashboardtimeoutid);
    //    setTimeout(function () {
    //        dashboardtimeoutid = search_apps($("#dashboard_search input.search-query").val());
    //    }, 500);
    //});

    //function search_apps(str) {
    //    if (!str) {
    //        $("#tenant_apps div[name='app']").show();
    //    } else {
    //        $("#tenant_apps div[name='app']").hide().filter(function () {
    //            return $(this).data("title").match(new RegExp(str, "i"));
    //        }).show();
    //    }
    //}    
    

    $("#appOrderFilter").on("change", function (event) {
        event.preventDefault();
        let $this = $(this);
        SortApps($this.val());
        SortLiteApps($this.val());
        
        //window.location = "/home/index?order=" + $this.val();
        $.ajax({
            url: '/home/saveuserapporder?order=' + $this.val(),
            type: 'GET',
            success: function () {
               // window.location = "/";
            },
            error: function (jqXHR) {

            }
        });
    });
   
    

    $("#tenant_apps div[name='app']").on("click", function () {
        let $this = $(this);

        let storedRecentApps = [];
        storedRecentApps = JSON.parse(window.localStorage.getItem('ls.recent-apps'));

        if (!storedRecentApps) {
            storedRecentApps = [];
        }

        // Save recent clicked apps to localstorage
        let recentAppId = $this[0].attributes['data-client-id'].nodeValue;
        let idx = storedRecentApps.indexOf(recentAppId);
        if (idx != -1) {
            storedRecentApps.splice(idx, 1);
        }
        storedRecentApps.push(recentAppId);
        window.localStorage.setItem('ls.recent-apps', JSON.stringify(storedRecentApps));
        if ($("#appOrderFilter").val() === '2') {
            SortApps(2);
            SortLiteApps(2);
        }
        
    });

    //sort documents to recent apps on first load if user's last app order was 2, because server side will not sort for apporder 2
    if (typeof userAppOrder !== 'undefined' && userAppOrder == 2 && document.querySelectorAll('[data-app-name="tenant_app_rows"]').length > 0 && window.localStorage.getItem('ls.recent-apps')) {
        SortApps(2);        
    }
    
});

let prevSortedOn;
let prevLiteSortedOn;

function SortApps(sortOn) {
    let masterdiv = document.getElementById('master_div');
    let childdiv = document.getElementById('tenant_apps');
    let rows = document.querySelectorAll('[data-app-name="tenant_app_rows"]');
    let rowArray = new Array();

    rowArray = sortingOperation(rows, sortOn, prevSortedOn);
    if (!rowArray || rowArray.length == 0 ) {
        prevSortedOn = sortOn;
        return false;
    }
    prevSortedOn = sortOn;

    let newChildDiv = document.createElement('div');
    newChildDiv.id = "tenant_apps";
    newChildDiv.className = "row";

    for (let i = 0, length = rowArray.length; i < length; i++) {
        newChildDiv.appendChild(rows[rowArray[i].oldIndex]);
    }

    if (newChildDiv.hasChildNodes()) {
        masterdiv.replaceChild(newChildDiv, childdiv);
    }

}


export function SortLiteApps(sortOn) {
    let masterdiv = document.getElementById('master_div_lite');
    let childdiv = document.getElementById('tenant_apps_lite');
    let rows = document.querySelectorAll('[data-app-name="tenant_app_rows_lite"]');

    let rowArray = new Array();

    rowArray = sortingOperation(rows, sortOn, prevLiteSortedOn);
    if (!rowArray || rowArray.length == 0) {
        prevLiteSortedOn = sortOn;
        return false;
    }
    prevLiteSortedOn = sortOn;

    let newChildDiv = document.createElement('div');
    newChildDiv.id = "tenant_apps_lite";
    newChildDiv.className = "dashboard-item-holder m-b-md clearfix";

    for (let i = 0, length = rowArray.length; i < length; i++) {
        newChildDiv.appendChild(rows[rowArray[i].oldIndex]);
    }

    if (newChildDiv.hasChildNodes()) {
        masterdiv.replaceChild(newChildDiv, childdiv);
    }

}

function sortingOperation(rows, sortOn, sortedOn) {
    let rowArray = new Array();
    for (let i = 0, length = rows.length; i < length; i++) {
        rowArray[i] = new Object;
        rowArray[i].oldIndex = i;
        rowArray[i].value = rows[i].attributes['data-title'].nodeValue;
        rowArray[i].id = rows[i].attributes['data-client-id'].nodeValue;
    }

    if (sortOn == 0) {
        if (sortedOn == 1) {
            rowArray.reverse();
        }
        else {
            rowArray.sort(RowCompare);
        }
    }
    else if (sortOn == 1) {
        if (sortedOn == 0) {
            rowArray.reverse();
        }
        else {
            rowArray.sort(RowCompare).reverse();
        }
    }
    else if (sortOn == 2) {
        let storedRecentApps = [];
        storedRecentApps = JSON.parse(window.localStorage.getItem('ls.recent-apps'));

        if (storedRecentApps) {
            //sort recent stored apps in desc becuse last pushed app should come first
            storedRecentApps = storedRecentApps.reverse();
            let recentApps = [];
            rowArray.forEach(function (item){
                let idx = storedRecentApps.indexOf(item.id);
                if (idx != -1) {
                    recentApps[idx] = item;
                }
            });

            let nonRecentApp = rowArray.filter(function (item) { return  storedRecentApps.indexOf(item.id) == -1}) ;
            nonRecentApp.sort(RowCompare);

            rowArray = [];
            rowArray = recentApps.concat(nonRecentApp);

        }
        else if (sortedOn != 0) {
            rowArray.sort(RowCompare);
        }
        else {
            rowArray = [];
            return rowArray;
        }
    }
    return rowArray;
}

function RowCompare(a, b) {
    let aVal = a.value.toLowerCase();
    let bVal = b.value.toLowerCase();
    return (aVal == bVal ? 0 : (aVal > bVal ? 1 : -1));
}